










































import { Component, Vue } from 'vue-property-decorator';
import StrategyRequestSentViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-request-sent-view-model';

@Component({ name: 'StrategyRequestSent' })
export default class StrategyRequestSent extends Vue {
  strategy_request_view_model = Vue.observable(new StrategyRequestSentViewModel(this));

  created() {
    this.strategy_request_view_model.initialize();
  }
}
